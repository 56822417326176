.devises-list {
    background-color: #f8f6ee;
    min-height: 100vh;
    display: flex;
    font-size: calc(10px + 2vmin);
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    /* color: rgb(153, 113, 113); */
}

.devises-flex {

    flex-direction: row; 
    align-items: center;
    justify-content: space-between;
    color: rgb(238, 200, 11); 

}
