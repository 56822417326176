.animleft {
    position: relative;
    animation-name: example;
    animation-duration: 1s;
  }
  
  @keyframes example {
    0%   { left:-100px; top:0px;}
    100% {left:0px; top:0px;}
  }

  .animright {
    position: relative;
    animation-name: example1;
    animation-duration: 1s;
  }
  
  @keyframes example1 {
    0%   { right:-160px; top:0px;}
    100% {right:0px; top:0px;}
  }
