

.App-header {
  /* background-color: #ffffff; */
  /* background-image:linear-gradient(180deg,#000 ,rgb(248, 179, 3) ); */
  min-height: 100vh;
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  /* font-size: calc(10px + 2vmin); */
  color: #000000b2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
